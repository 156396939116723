.date-picker.container {
  width: 35px;
  .icon {
    color: #aaa;
    cursor: pointer;
  }
  .clear {
    margin-right: 8px;
  }
  .entryContainer {
    height: 0px;
    display: block;
  }
  .pickerContainer {
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 10px 10px 55px rgba(0, 0, 0, 0.2);
    position: relative;
    left: 50px;
    bottom: 200px;
    width: 230px;
    height: auto;
    z-index: 200;
    .day-picker-header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      background: #ddd;
      color: #085b55;
    }
  }
  .outOfBounds {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 199;
  }
  .calender-controls {
    background: #fff;
    padding: 5px;
    .push-days-container {
      display: flex;
      justify-content: space-around;
      .calender-btn {
        display: inline-block;
        margin: 0;
        padding: 5px 10px;
        border-radius: 4px;
      }
    }
    .custom-days-input {
      display: grid;
      grid-template-columns: 1fr auto auto;
      grid-gap: 5px;
      align-items: center;
      input {
        height: 30px;
        padding: 5px;
      }
    }
  }
}

.dashboard-picker .date-picker.container {
  width: 100%;
  text-align: center;
}
