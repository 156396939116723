.patient-hcpc-history-tab {
  font-size: 16px;
  .eligibility-table-header {
    display: grid;
    text-shadow: 2px 3px 5px rgba(0,0,0,0.15);
    grid-template-columns: repeat(6, 1fr) 70px;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 5px;
  }
  .eligibility-table-row {
    padding: 5px;
    cursor: pointer;
    display: grid;
    border-top: 1px solid #ddd;
    background-color: #dfdfdf;
    grid-template-columns: repeat(6, 1fr) 70px;
    align-items: center;
    justify-content: center;
    justify-items: flex-start;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .eligibility-table-row:hover {
    background-color: #ccc;
  }

  .eligibility-history-form {
    display: flex;
    flex-direction: row;
    padding-left: 50px;
    align-items: center;
  }

  .hcpc-history-table-header,
  .hcpc-history-table-row {
    display: grid;
    padding-left: 40px;
    border-top: 1px solid #ddd;
    grid-template-columns: 60px 100px 50px 80px 80px 1fr 2fr 1fr 60px;
    grid-gap: 20px;
    align-items: center;
    justify-items: flex-start;
    > div {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  .hcpc-history-table-header {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .hcpc-history-table {
    background-color: #fdfdfd;
  }
}
