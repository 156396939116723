.modal-window {
  $base-z: 5;
  $z-interval: 2;

  :focus {
    outline: none;
  }

  .modal-window-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    opacity: 0.75;
    z-index: $base-z;
  }

  .modal-window-content-container {
    position: absolute;
    top: 5rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
    box-shadow: 1px 0 10px 5px rgba(0, 0, 0, 0.3);
    padding: 0px;
    background: #fff;
    z-index: $base-z + $z-interval;
    &.chat-active {
      right: $twilio-chat-width;
    }

    &.bigger-max-width{
      max-width:1000px;
    }

    &.large {
      $pad: 2rem;
      top: 3rem;
      left: $pad;
      right: $pad;
      &.chat-active {
        right: calc(#{$pad} + #{$twilio-chat-width});
      }
      height: calc(100vh - 105px);
      max-width: none;
      max-height: none;
    }
  }

  .modal-window-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: calc(100vh - 125px);
    overflow-y: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background: inherit;
  }

  .modal-status-bar {
    display: flex;
    justify-content: flex-end;
    background: #f3f3f3;

    .modal-status-bar-title{
      justify-content: center;
    }
  }

  .close-modal-x {
    cursor: pointer;
    font-size: 20px;
    line-height: 1;
    color: #4582a4;
    transition: all 0.2s ease-in-out;
    svg:hover {
      transform: scale(1.1);
    }
  }
}
