.date-search-container {
    display: flex;
    width: 700px;
    align-items: center;
    justify-content: space-around;
}

.date-from-to-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(0, 0, 0);
}

.date-search-input {
    width: auto !important;
    margin-bottom: 0px !important;
}

.selected-appointments {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
    margin: 0px 15px;
}