.copied-animation {
    animation: bounce 1s linear;
}
  
.copied-animation:before {
    content: "Copied";
    position: absolute;
    top: -20px;
    left: -3px;
    opacity: 0.8;
    animation: fade 2s ease-in-out;
}