.upcoming-chats {
  display: flex;
  flex-direction: column;
  flex: 1;
  .no-upcoming-chats-msg {
    display: flex;
    flex: 1;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    margin: 50px;
    border: 1px solid #deeaee;
    width: 400px;
    font-size: 18px;
    padding: 30px;
  }
  .upcoming-chats-container {
    height: auto;
    overflow-y: auto;
    .chat-info {
      display: grid;
      grid-template-columns: 1fr 250px;
      grid-gap: 20px;
      background: #fff;
      border: 1px solid #deeaee;
      padding: 20px;
      margin-bottom: 15px;
      .chat-header {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
      }

      .chat-header-content{
        text-align: 'center'
      }
    }
  }
  .join-chat-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    button {
      margin: 0;
    }
    .chat-remaining-time {
      padding-top: 10px;
      color: #888;
    }

    .join-chat-button-container-canceled{
      color: rgb(242, 21, 21);
      font-size: medium;
    }
  }
}
